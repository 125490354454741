/* global ywcas_search_results_block_parameter */
const {
    showAutoComplete,
    minChars,
    skuLabel,
    addToCartLabel,
    readMoreLabel,
    selectOptionsLabel,
    ajaxURL,
    inStockLabel,
    outOfStockLabel,
    wcData,
    ywcasBuildBlockURL,
    lang,
    siteURL,
    classicDefaultSettings,
    defaultQuery,
    historySearches,
    popularSearches,
    zeroResults,
    fuzzyResults
} = ywcas_search_results_block_parameter;

export const ywcas_wc_data = wcData;
export const history = historySearches;
export const popular = popularSearches;
export const YWCAS_ADD_TO_CART_LABEL = addToCartLabel;
export const YWCAS_AJAX_URL = ajaxURL;
export const YWCAS_IN_STOCK_LABEL = inStockLabel;
export const YWCAS_SKU_LABEL = skuLabel;
export const YWCAS_BUILD_BLOCK_URL = ywcasBuildBlockURL;
export const YWCAS_OUT_OF_STOCK_LABEL = outOfStockLabel;
export const YWCAS_LANG = lang;
export const YWCAS_SITE_URL = siteURL;
export const ywcasDefaultSettings = classicDefaultSettings;
export const YWCAS_READ_MORE = readMoreLabel;
export const YWCAS_SELECT_OPTIONS = selectOptionsLabel;
export const YWCAS_HAS_AUTOCOMPLETE = 'yes' === showAutoComplete;
export const YWCAS_MIN_CHARS = parseInt(minChars);
export const YWCAS_DEFAULT_QUERY = defaultQuery;
export const YWCAS_ZERO_RESULTS = zeroResults;
export const YWCAS_FUZZY_RESULTS = fuzzyResults;