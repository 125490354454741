/**
 * Internal dependencies
 */
import FILLED_BLOCK from './filled-block/block.json';
import INPUT_BLOCK from './input-block/block.json';
import PRODUCT_RESULTS_BLOCK from './product-results-block/block.json';


export default {
  FILLED_BLOCK,
  INPUT_BLOCK,
  PRODUCT_RESULTS_BLOCK
};
