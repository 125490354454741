import {Children, cloneElement, isValidElement} from '@wordpress/element';

import {getValidBlockAttributes} from "../../base/utils";
import {getRegisteredBlockComponents} from '../../blocks-registry/block-components';
import {renderParentBlock} from "../../atomic/utils";
import './inner-blocks/register-components';
import {blockName, blockAttributes} from './attributes';
import Block from './block';


const getProps = (el) => {
    return {
        attributes: getValidBlockAttributes(
            blockAttributes,
            el ? el.dataset : {}
        ),
    };
};

const Wrapper = ({children}) => {
    return Children.map(children, (child) => {
        if (isValidElement(child)) {
            return cloneElement(child);
        }
        return child;
    })
};

renderParentBlock({
    Block,
    blockName,
    selector: '.wp-block-yith-search-block',
    getProps,
    blockMap: getRegisteredBlockComponents(blockName),
    blockWrapper: Wrapper,
});
