import {__} from '@wordpress/i18n';

export const blockName = 'yith/search-block';
export const blockAttributes = {
  isPreview: {
    type: 'boolean',
    default: false,
  },
  align: {
    type: 'string',
    default: 'wide',
  },
  size:{
    type:'string',
    default: 'sm'
  },
  currentView: {
    type: 'string',
    default: 'yith/input-block',
    source: 'readonly', // custom source to prevent saving to post content
  },
  editorViews: {
    type: 'object',
    default: [
      {
        view: 'yith/input-block',
        label: __( 'Search Input', 'yith-woocommerce-ajax-search' ),
      },
      {
        view: 'yith/filled-block',
        label: __( 'Filled Results', 'yith-woocommerce-ajax-search' ),
      },
    ],
  },
};
